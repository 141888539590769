import { Box, Container, Grid } from 'components/layout'

const RichText = ({ copy }) => (
  <Box py={['37px', '60px']}>
    <Container>
      <Grid col={['1/13', '1/7']}>
        {copy}
      </Grid>
    </Container>
  </Box>
)

export default RichText
