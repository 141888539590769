import React from 'react'
import { node, number, shape } from 'prop-types'
import useSWR from 'swr'
import { get } from 'lodash'
import { Button } from 'components/buttons'
import { Marquee } from 'components/carousel'
import { Instagram } from 'components/icons'
import { Box, FlexContainer, FlexGrid } from 'components/layout'
import { Image } from 'components/media'
import { Text } from 'components/text'

const fetcher = (...args) => fetch(...args).then((res) => res.json())

const InstagramFeed = ({ cta, headline, limit }) => {
  const { data, error } = useSWR(
    `/api/instagram?limit=${limit}`,
    fetcher,
  )

  if (error) {
    console.error(error)
    return null
  }

  return (
    <Box backgroundColor="black" py={['90px', '140px']} overflow="hidden">
      <FlexContainer>
        <FlexGrid cols={12}>
          <Box position="absolute" top={['-40px', '-55px']} left="-4px" zIndex="1">
            <Text as="h3">
              {headline}
            </Text>
          </Box>
        </FlexGrid>
      </FlexContainer>
      <Marquee>
        {!!data?.media && (
          <Box display="inline-flex" flexWrap="nowrap">
            {get(data, 'media', []).map((item, index) => (
              <Box
                key={item.id}
                display="inline-table"
                mr="20px"
                mt={[`${index % 2 === 0 ? 34 : 0}px`, `${index % 2 === 0 ? 87 : 0}px`]}
                position="relative"
                width={['160px', '300px']}
                height={['160px', '300px']}
              >
                <Box
                  overflow="hidden"
                  pt="100%"
                  position="relative"
                  height="0px"
                  width="100%"
                >
                  <Image
                    alt={item.caption}
                    src={item.thumbnail_url || item.media_url}
                    position="absolute"
                    top="0"
                    right="0"
                    bottom="0"
                    left="0"
                  />
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Marquee>
      <FlexContainer>
        {cta && (
          <FlexContainer justifyContent="center" mt={['20px', '40px']}>
            <Button href={cta.href} icon={<Instagram color="black" size={24} />} my="auto">
              {cta.label}
            </Button>
          </FlexContainer>
        )}
      </FlexContainer>
    </Box>
  )
}

InstagramFeed.propTypes = {
  cta: shape(),
  headline: node,
  limit: number,
}

InstagramFeed.defaultProps = {
  cta: null,
  headline: null,
  limit: 24,
}

export default InstagramFeed
