import React from 'react'
import { arrayOf, node, shape } from 'prop-types'
import { Box, FlexContainer, FlexGrid } from 'components/layout'
import Text from 'components/text/Text'
import ProductCarousel from './ProductCarousel'

const ProductTray = ({ headline, products }) => (
  <Box mt={['100px', '144px']} mb={['90px', '100px']}>
    <FlexContainer position="relative">
      <FlexGrid cols={12}>
        <ProductCarousel products={products} />
      </FlexGrid>
      <Box position="absolute" top="-60px" left="-4px" overflow="hidden">
        <Text as="h3">
          {headline}
        </Text>
      </Box>
    </FlexContainer>
  </Box>
)

ProductTray.propTypes = {
  headline: node,
  products: arrayOf(shape()),
}

ProductTray.defaultProps = {
  headline: '',
  products: [],
}

export default ProductTray
