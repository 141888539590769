import React from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'
import { Logo } from 'components/icons'
import { Box } from 'components/layout'
// import { Image } from 'components/media'
import { Text } from 'components/text'
import theme from 'styles/theme'

const HeroContainer = styled(Box)`
  ${layout}
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  overflow: hidden;

  svg {
    width: 100%;
    height: auto;
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    padding-top: 55px;
    padding-left: ${theme.grid.margin.lg};
    padding-right: ${theme.grid.margin.lg};
  }
`

const BrandHero = ({ copy }) => (
  <HeroContainer
    backgroundImage="url('/images/brand-hero-waves.svg')"
    backgroundPosition="top right"
    backgroundRepeat="no-repeat"
    backgroundSize={['118px 118px', '225px 225px', '235px 235px', '250px 250px', '350px 350px']}
    pt={['69px']}
    pb={['76px']}
  >
    <Text
      className="inline-desktop"
      as="span"
      color="black"
      fontSize={['37px', '37px', '90px', '115px', '132px']}
      fontWeight="900"
      fontFamily={theme.fonts.RocGrotesk}
      textTransform="uppercase"
    >
      {copy}
    </Text>
    <Logo />
    {/* {media?.src && <Image src={media.src} alt={media.alt} />} */}
  </HeroContainer>
)

export default BrandHero
