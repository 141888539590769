import React, { forwardRef, useCallback } from 'react'
import {
  func,
  instanceOf, node, number, shape,
} from 'prop-types'
import styled from 'styled-components'
import Button from 'components/buttons/Button'
import { Box, Container, Grid } from 'components/layout'
import { Image } from 'components/media'
import { Text } from 'components/text'

import theme from 'styles/theme'

const Wrapper = styled(Box)`
  transition: transform 0.3s ease-in-out;
  transform: translateX(${(props) => props.offset * 100}%)
`

const CtaWrapper = styled(Box)`
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    transform: translateX(-50%) translateY(-50%);
  }
`

const CarouselItem = forwardRef(({
  copy,
  cta,
  headline,
  media,
  offset,
  title,
  onImageLoad,
}, ref) => {
  const handleImageLoad = useCallback(() => {
    onImageLoad()
  }, [onImageLoad])

  return (
    <Wrapper
      offset={offset}
      position="absolute"
      top="0"
      left="0"
      right="0"
    >
      <Container ref={ref}>
        <Grid col="1/13">
          <Box position="relative">
            {media && (
              <Image
                src={media.src}
                alt={media.alt}
                onLoad={handleImageLoad}
              />
            )}
            {cta && (
              <CtaWrapper
                position={['relative', 'absolute']}
                top={['0', '50%']}
                left={['0', '50%']}
              >
                <Button href={cta.href}>
                  {cta.label}
                </Button>
              </CtaWrapper>
            )}
            {headline && (
              <Box position="absolute" top={['-30px', '-70px']} left="0" right="0">
                <Text
                  as="h4"
                  textAlign="center"
                  color="white"
                  fontFamily={theme.fonts.RocGroteskWide}
                  width="100%"
                >
                  {headline}
                </Text>
              </Box>
            )}
          </Box>
          <Box>
            {title && (
              <Text as="h5" color="white" textAlign="center" m="0" mt={['20px', '30px']}>
                {title}
              </Text>
            )}
            {copy && (
              <Text
                color="white"
                fontSize={['14px', '24px']}
                maxWidth="600px"
                mx="auto"
                mt={['15px', '25px']}
                textAlign="center"
              >
                {copy}
              </Text>
            )}
          </Box>
        </Grid>
      </Container>
    </Wrapper>
  )
})

CarouselItem.displayName = 'CarouselItem'

CarouselItem.propTypes = {
  copy: instanceOf([node, shape]),
  cta: shape(),
  headline: node,
  media: shape(),
  offset: number,
  title: instanceOf([node, shape]),
  onImageLoad: func,
}

CarouselItem.defaultProps = {
  copy: null,
  cta: null,
  headline: null,
  media: null,
  offset: 0,
  title: null,
  onImageLoad: () => {},
}

export default CarouselItem
