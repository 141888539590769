import React from 'react'
import { Box, Container, Grid } from 'components/layout'
import { Image } from 'components/media'
import { Text } from 'components/text'
import theme from 'styles/theme'

const MediaCopy = ({ copy, headline, media }) => (
  <Box backgroundColor="black" position="relative" pt={['124px', '224px']} pb={['47px', '147px']}>
    <Box position="absolute" top={['90px', '160px']} left="0" zIndex={theme.zIndices.front}>
      <Text as="h3">
        {headline}
      </Text>
    </Box>
    <Container>
      <Grid col={['1/13', '2/7']} row={1}>
        <Image src={media.src} alt={media.alt} />
      </Grid>
      <Grid col={['1/13', '8/12']} row={[2, 1]} mt={['40px', '0px']}>
        <Text color="white" fontSize={['24px']}>
          {copy}
        </Text>
      </Grid>
    </Container>
  </Box>
)

export default MediaCopy
