import React from 'react'
import { number } from 'prop-types'
import { Box, FlexContainer, FlexGrid } from 'components/layout'
import LogoMask from 'components/icons/LogoMask'

const isActive = (prog, size) => {
  const threshold = {
    1: 0.3,
    2: 0.4,
    3: 0.45,
    4: 0.5,
  }
  const active = prog >= threshold[size]
  return {
    opacity: active ? 1 : 0,
  }
}

const BrandLogoAnimation = ({ progress }) => (
  <FlexContainer justifyContent="center">
    <FlexGrid cols={[12, 12, 6]}>
      <Box>
        <LogoMask align="bottom" size={4} style={isActive(progress, 4)} />
        <LogoMask align="bottom" size={3} style={isActive(progress, 3)} />
        <LogoMask align="bottom" size={2} style={isActive(progress, 2)} />
        <LogoMask align="bottom" size={1} mb={['90px', '90px', '100px']} style={isActive(progress, 1)} />
        <LogoMask align="top" size={1} style={isActive(progress, 1)} />
        <LogoMask align="top" size={2} style={isActive(progress, 2)} />
        <LogoMask align="top" size={3} style={isActive(progress, 3)} />
        <LogoMask align="top" size={4} style={isActive(progress, 4)} />
      </Box>
    </FlexGrid>
  </FlexContainer>
)

BrandLogoAnimation.propTypes = {
  progress: number,
}

BrandLogoAnimation.defaultProps = {
  progress: 0,
}

export default BrandLogoAnimation
