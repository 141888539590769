import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import Image from './Image'

const Container = styled.div`
  display: flex;
  align-items: center;
  max-height: 800px;
`

const FluidImage = ({ image }) => (
  <Container>
    <Image src={image} alt="" />
  </Container>
)

FluidImage.propTypes = {
  image: string.isRequired,
}

export default FluidImage
