import React from 'react'
import { number, shape, string } from 'prop-types'
import { InstagramFeed } from 'components/sections'
import { Headline } from 'components/text'

const InstagramFeedWrapper = ({
  ctaCopy, ctaLink, headline, numberOfImages,
}) => (
  <InstagramFeed
    cta={{ href: ctaLink, label: ctaCopy }}
    headline={<Headline color="white" document={headline} fontSize={['60px', '100px']} />}
    limit={numberOfImages}
  />
)

InstagramFeedWrapper.propTypes = {
  ctaCopy: string,
  ctaLink: string,
  headline: shape(),
  numberOfImages: number,
}

InstagramFeedWrapper.defaultProps = {
  ctaCopy: null,
  ctaLink: null,
  headline: null,
  numberOfImages: undefined,
}

export default InstagramFeedWrapper
