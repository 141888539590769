import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { fieldsParser } from 'contentful-parsers'
import styled from 'styled-components'
import { typography } from 'styled-system'
import { Button } from 'components/buttons'
import { Box } from 'components/layout'
import { RichText } from 'components/sections'
import { Text } from 'components/text'
import parseLineBreaks from 'utils/parseLineBreaks'
import theme from 'styles/theme'

const Paragraph = styled(Text)`
  ${typography}

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
`

const OrderedList = styled.ol`
  ${typography}
  list-style: georgian outside !important;
  padding-left: 24px !important;
`

const UnorderedList = styled.ul`
  ${typography}
  list-style: disc outside !important;
  padding-left: 24px !important;
`

const ListItem = styled.li`

`

const HEADER_MARGIN = 5
const BLOCK_MARGIN = 4

// MARKS
const MarksBold = (text) => (
  <Text as="b" fontWeight="800">{parseLineBreaks(text)}</Text>
)

const MarksItalic = (text) => (
  <Text as="i" fontStyle="italic">{parseLineBreaks(text)}</Text>
)

const MarksUnderline = (text) => (
  <Text as="u" textDecoration="underline">{parseLineBreaks(text)}</Text>
)

const MarksCode = (text) => (
  <Text as="code">{parseLineBreaks(text)}</Text>
)

// BLOCKS
const NodeBlocksDocument = (node, children) => (
  <Box>{children}</Box>
)

const NodeBlocksParagraph = (node, children) => (
  <Paragraph
    as="p"
    fontFamily={theme.fonts.OpenSans}
    fontSize={['14px', '18px']}
    lineHeight="1.3"
    mb={BLOCK_MARGIN}
  >
    {children}
  </Paragraph>
)

const NodeBlocksHeading1 = (node, children) => (
  <Text
    as="h1"
    fontFamily={theme.fonts.RocGroteskCondensed}
    fontSize={['40px', '90px']}
    fontWeight="600"
    textTransform="uppercase"
    mt={HEADER_MARGIN}
    mb={BLOCK_MARGIN}
  >
    {parseLineBreaks(children)}
  </Text>
)

const NodeBlocksHeading2 = (node, children) => (
  <Text
    as="h2"
    fontSize={['30px', '48px']}
    fontWeight="800"
    mt={HEADER_MARGIN}
    mb={BLOCK_MARGIN}
  >
    {parseLineBreaks(children)}
  </Text>
)

const NodeBlocksHeading3 = (node, children) => (
  <Text
    as="h3"
    fontSize={['16px', '24px']}
    fontWeight="500"
    textTransform="uppercase"
    mt={HEADER_MARGIN}
    mb={BLOCK_MARGIN}
  >
    {parseLineBreaks(children)}
  </Text>
)

const NodeBlocksHeading4 = (node, children) => (
  <Text
    as="h4"
    fontFamily={theme.fonts.OpenSans}
    fontSize={['16px', '24px']}
    mt={HEADER_MARGIN}
    mb={BLOCK_MARGIN}
  >
    {parseLineBreaks(children)}
  </Text>
)

const NodeBlocksHeading5 = (node, children) => (
  <Text
    as="h5"
    fontFamily={theme.fonts.OpenSans}
    fontSize={['16px', '24px']}
    mt={HEADER_MARGIN}
    mb={BLOCK_MARGIN}
  >
    {parseLineBreaks(children)}
  </Text>
)

const NodeBlocksULList = (node, children) => (
  <UnorderedList fontSize={['14px', '18px']}>{children}</UnorderedList>
)

const NodeBlocksOLList = (node, children) => (
  <OrderedList fontSize={['14px', '18px']}>{children}</OrderedList>
)

const NodeBlocksListItem = (node, children) => (
  <ListItem>{children}</ListItem>
)

const NodeBlocksQuote = (node, children) => (
  <blockquote>{children}</blockquote>
)

const NodeBlocksHR = () => (
  <hr />
)

// INLINES
const NodeInlinesHyperlink = ({ data }, children) => (
  <a href={data?.uri} target="_blank" rel="nofollow noopener noreferrer">{children}</a>
)

const NodeInlinesAssetHyperlink = ({ data }, children) => {
  const target = fieldsParser(data?.target)

  return (
    <Button
      backgroundColor="black"
      display={['block', 'flex']}
      labelColor="white"
      href={target?.file?.url}
      target="_blank"
      rel="nofollow noopener noreferrer"
      my={BLOCK_MARGIN}
    >
      {children}
    </Button>
  )
}

const options = {
  renderMarks: {
    [MARKS.BOLD]: MarksBold,
    [MARKS.ITALIC]: MarksItalic,
    [MARKS.UNDERLINE]: MarksUnderline,
    [MARKS.CODE]: MarksCode,
  },
  renderNode: {
    [BLOCKS.DOCUMENT]: NodeBlocksDocument,
    [BLOCKS.PARAGRAPH]: NodeBlocksParagraph,
    [BLOCKS.HEADING_1]: NodeBlocksHeading1,
    [BLOCKS.HEADING_2]: NodeBlocksHeading2,
    [BLOCKS.HEADING_3]: NodeBlocksHeading3,
    [BLOCKS.HEADING_4]: NodeBlocksHeading4,
    [BLOCKS.HEADING_5]: NodeBlocksHeading5,
    [BLOCKS.UL_LIST]: NodeBlocksULList,
    [BLOCKS.OL_LIST]: NodeBlocksOLList,
    [BLOCKS.LIST_ITEM]: NodeBlocksListItem,
    [BLOCKS.QUOTE]: NodeBlocksQuote,
    [BLOCKS.HR]: NodeBlocksHR,
    [INLINES.HYPERLINK]: NodeInlinesHyperlink,
    [INLINES.ASSET_HYPERLINK]: NodeInlinesAssetHyperlink,
  },
}

const RichTextWrapper = ({ copy }) => (
  <RichText copy={documentToReactComponents(copy, options)} />
)

export default RichTextWrapper
