import React, { useState } from 'react'
import {
  node, oneOfType, shape, string,
} from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import { Box, FlexContainer, FlexGrid } from 'components/layout'
import Text from 'components/text/Text'
import theme from 'styles/theme'

const Form = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.lg}) {
    flex-direction: row;
  }

  input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
    outline: none;
    border: 1px solid black;
    border-radius: 0px;
    height: 48px;
    line-height: 48px;
  }

  &.error input {
    border: 1px solid #D14124;
  }

  button {
    background: black;
    border-radius: 0px;
    outline: none;
    appearance: none;
    cursor: pointer;
    box-shadow: none;
    border: none;
    height: 48px;
    text-align: center;
    margin-top: 15px;


    @media (min-width: ${theme.breakpoints.lg}) {
      margin-top: 0px;
      text-align: left;
    }
  }
`

const NewsLetter = ({
  copy, cta, emailPlaceholder, headline,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = async () => {
    if (value.trim() === '') {
      setMessage('Please enter a valid email address')
      setError(true)
    }
    setSubmitting(true)
    setMessage('submitting...')
    const res = await axios.post('/api/newsletter', {
      email: value,
    })
    if (res.data.success) {
      setMessage('Signed up!')
      setError(false)
      setTimeout(() => {
        setValue('')
        setMessage('')
        setSubmitting(false)
      }, 2000)
    } else {
      setMessage('Please enter a valid email address')
      setError(true)
      setSubmitting(false)
    }
  }

  return (
    <Box
      backgroundColor="white"
      backgroundImage="url('/images/newsletter-waves.svg')"
      backgroundPosition="bottom right"
      backgroundRepeat="no-repeat"
      backgroundSize={['228px 228px', '282px 282px']}
      pt={['40px', '110px']}
      pb={['180px', '110px']}
    >
      <FlexContainer alignItems="center">
        <FlexGrid cols={[12, 12, 6, 6, 5]}>
          <Text
            color="black"
            fontSize={['30px', '30px', '48px']}
            fontWeight="800"
            fontFamily={theme.fonts.RocGrotesk}
            textTransform="uppercase"
            pb={['15px', '20px']}
          >
            {headline}
          </Text>
          <Text
            as="div"
            color="black"
            fontSize={['14px', '14px', '18px']}
            fontWeight="700"
            fontFamily={theme.fonts.RocGrotesk}
            pb={['0px', '30px', '0px']}
          >
            {copy}
          </Text>
        </FlexGrid>
        <FlexGrid cols={[12, 12, 6]} pt="30px">
          <Form className={error ? 'error' : ''}>
            <Text
              as="input"
              color="black"
              fontSize={['12px', '12px', '18px']}
              fontWeight="400"
              fontFamily={theme.fonts.RocGrotesk}
              textTransform="uppercase"
              type="text"
              value={value}
              placeholder={emailPlaceholder}
              onChange={(e) => setValue(e.target.value)}
              disabled={submitting}
            />
            <Text
              as="button"
              color="white"
              fontSize={['14px', '14px', '20px']}
              fontWeight="700"
              fontFamily={theme.fonts.RocGrotesk}
              px="45px"
              onClick={onSubmit}
              style={{
                whiteSpace: 'nowrap',
              }}
              textTransform="uppercase"
            >
              {cta.label}
            </Text>
          </Form>
          <Text
            color="black"
            fontSize={['12px', '12px', '18px']}
            fontWeight="400"
            fontFamily={theme.fonts.RocGrotesk}
            pt={['10px', '10px', '10px']}
            minHeight="30px"
          >
            {message.toUpperCase()}
          </Text>
        </FlexGrid>
      </FlexContainer>
    </Box>
  )
}

NewsLetter.propTypes = {
  copy: oneOfType([node, string]),
  cta: shape(),
  emailPlaceholder: string,
  headline: oneOfType([node, string]),
}

NewsLetter.defaultProps = {
  copy: 'Get the latest Beatnic buzz right in your inbox.',
  cta: { href: '', label: 'Sign Up' },
  emailPlaceholder: 'What’s your email?',
  headline: 'Keep In Touch',
}

export default NewsLetter
