import React from 'react'
import { Box, Container, Grid } from 'components/layout'
import { Image } from 'components/media'
import { Text } from 'components/text'
import theme from 'styles/theme'

const SimpleHero = ({ copy, headline, media }) => (
  <Box
    backgroundColor="black"
    backgroundImage={["url('/images/location-waves.svg')"]}
    backgroundPosition="bottom right"
    backgroundRepeat="no-repeat"
    backgroundSize={['150%', '100%', '100%', '100%', '100%']}
    overflow="hidden"
    pb={['100px', '130px', '150px', '180px', '200px']}
  >
    <Container>
      <Grid col={['1/13', '1/7']}>
        <Text
          as="h1"
          color="white"
          fontFamily={theme.fonts.RocGroteskCondensed}
          fontSize={['40px', '90px']}
          fontWeight="600"
          textTransform="uppercase"
        >
          {headline}
        </Text>
        <Text color="white" fontSize={['16px', '24px']} mt={['8px', '10px']}>
          {copy}
        </Text>
      </Grid>
    </Container>
    {media?.src && (
      <Container>
        <Grid col="1/13">
          <Image src={media.src} alt={media.alt} />
        </Grid>
      </Container>
    )}
  </Box>
)

export default SimpleHero
