import React from 'react'
import styled from 'styled-components'
import { Box } from 'components/layout'
import { oneOf } from 'prop-types'
import theme from 'styles/theme'
import Logo from './Logo'

const Mask = styled(Box)`
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-top: 7px;
  @media (min-width: ${theme.breakpoints.lg}) {
    margin-top: 14px;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  &.align-top {
    align-items: flex-start;
  }
  &.align-bottom {
    align-items: flex-end;
  }

  &.size-1 {
    height: 8px;
    @media (min-width: ${theme.breakpoints.lg}) {
     height: 20px;
    }
  }
  &.size-2 {
    height: 15px;
    @media (min-width: ${theme.breakpoints.lg}) {
      height: 30px;
    }

  }
  &.size-3 {
    height: 20px;
    @media (min-width: ${theme.breakpoints.lg}) {
      height: 40px;
    }
  }
  &.size-4 {

  }
`

const LogoMask = ({ align, size, ...other }) => (
  <Mask className={`size-${size} align-${align}`} {...other}>
    <Logo fill="white" />
  </Mask>
)

LogoMask.propTypes = {
  align: oneOf(['top', 'bottom']),
  size: oneOf([1, 2, 3, 4]),
}

LogoMask.defaultProps = {
  align: 'top',
  size: 1,
}

export default LogoMask
