import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { SimpleHero } from 'components/hero'

const SimpleHeroWrapper = ({ copy, headline, media }) => (
  <SimpleHero
    copy={documentToReactComponents(copy)}
    headline={documentToReactComponents(headline)}
    media={{ src: media?.file?.url, alt: media?.file?.description }}
  />
)

export default SimpleHeroWrapper
