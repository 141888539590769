import React, { useEffect, useState, useRef } from 'react'
import { node } from 'prop-types'

const AnimationContainer = ({ children, ...other }) => {
  const [progress, setProgress] = useState(0)
  const ref = useRef()

  const onScroll = () => {
    const rect = ref.current.getBoundingClientRect()
    const h = window.innerHeight
    const normalizedTop = -1 * (rect.top - h)
    const unclamped = normalizedTop / (h + rect.height)
    setProgress(Math.max(0, Math.min(1, unclamped)))
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    onScroll()
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const childrenWithProgress = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { progress })
    }
    return child;
  })

  return (
    <div ref={ref} {...other}>
      {childrenWithProgress}
    </div>
  )
}

AnimationContainer.propTypes = {
  children: node,
}

AnimationContainer.defaultProps = {
  children: null,
}

export default AnimationContainer
