import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ProductTray from 'components/carousel/ProductTray'
import { Headline } from 'components/text'

const ProductTrayWrapper = ({ headline, productTrayCards }) => {
  const products = productTrayCards.map((item) => ({
    id: item?.id,
    image: item?.productImage?.file?.url,
    title: documentToReactComponents(item?.productName),
    link: item?.productLink,
    label: item?.label,
  }))

  return (
    <ProductTray
      headline={<Headline color="black" document={headline} fontSize={['60px', '60px', '100px']} style={{ whiteSpace: 'nowrap' }} />}
      products={products}
    />
  )
}

ProductTrayWrapper.propTypes = {
  headline: shape(),
  productTrayCards: arrayOf(shape()),
}

ProductTrayWrapper.defaultProps = {
  headline: null,
  productTrayCards: [],
}

export default ProductTrayWrapper
