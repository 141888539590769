import React, { useCallback, useEffect, useState } from 'react'
import { arrayOf, shape } from 'prop-types'
import styled from 'styled-components'
import useRefMap from 'use-ref-map'
import { Box, Container, FlexContainer } from 'components/layout'
import CarouselItem from './CarouselItem'

const ArrowButton = styled(Box)`
  display: block;
  transform: rotate(${(props) => (props.direction === 'right' ? 0 : 180)}deg);
`

const Carousel = ({ items }) => {
  const [index, setIndex] = useState(0)
  const [height, setHeight] = useState(1021)
  const { getRef, setRef } = useRefMap()

  const handleClick = useCallback((evt) => {
    const direction = parseInt(evt.currentTarget.dataset.direction, 10)
    const nextIndex = Math.max(0, Math.min(index + direction, items.length - 1))
    setIndex(nextIndex)
  }, [items, index])

  const updateHeight = () => {
    let nextHeight

    items.forEach((item, itemIndex) => {
      const itemRef = getRef(`${item.id}-${itemIndex}`)
      const clientHeight = itemRef.current?.clientHeight

      if (clientHeight) {
        const refHeight = clientHeight < 450 && items.length <= 1
          ? clientHeight + 120
          : clientHeight + 240

        if (!nextHeight || refHeight > nextHeight) {
          nextHeight = refHeight
        }
      }
    })

    if (nextHeight) {
      setHeight(nextHeight)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      updateHeight()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleItemImageLoad = () => {
    updateHeight()
  }

  return (
    <Box
      backgroundColor="black"
      py={['68px', '140px']}
      position="relative"
      minHeight={`${height}px`}
      overflow="hidden"
    >
      <Container>
        {items.map((item, itemIndex) => (
          <CarouselItem
            key={item.id}
            ref={setRef(`${item.id}-${itemIndex}`)}
            {...item}
            offset={
              (itemIndex < index && -1)
              || (itemIndex > index && 1)
              || 0
            }
            onImageLoad={handleItemImageLoad}
          />
        ))}
      </Container>
      {items.length > 1 && (
        <FlexContainer justifyContent="space-between" position="absolute" bottom={['60px', '120px']} left="0" width="100%">
          <ArrowButton
            data-direction={-1}
            direction="left"
            onClick={handleClick}
          >
            <img src="/icons/arrow.svg" alt="Left" />
          </ArrowButton>
          <ArrowButton
            data-direction={1}
            direction="right"
            onClick={handleClick}
          >
            <img src="/icons/arrow.svg" alt="Right" />
          </ArrowButton>
        </FlexContainer>
      )}
    </Box>
  )
}

Carousel.propTypes = {
  items: arrayOf(shape()),
}

Carousel.defaultProps = {
  items: [],
}

export default Carousel
