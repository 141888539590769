import React from 'react'
import { Box } from 'components/layout'
import AnimationContainer from 'components/animation/AnimationContainer'
import BrandLogoAnimation from './BrandLogoAnimation'

const BrandLogo = () => (
  <Box backgroundColor="black" py={['40px', '40px', '100px']}>
    <AnimationContainer>
      <BrandLogoAnimation />
    </AnimationContainer>
  </Box>
)

export default BrandLogo
