import React, {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { node } from 'prop-types'
import { useInView } from 'react-hook-inview'
import { Box } from 'components/layout'

const Marquee = ({ children }) => {
  const [animate, setAnimate] = useState(false)
  const [offset, setOffset] = useState(0)
  const [cloneCount, setCloneCount] = useState(1)

  const childrenRef = useRef(null)
  const animationFrameRef = useRef(null)

  const [wrapperRef, inView] = useInView()

  useEffect(() => {
    const tick = () => {
      setOffset((prevState) => {
        if (!childrenRef?.current) return prevState

        let nextOffset = prevState + 1

        if (nextOffset >= childrenRef?.current?.clientWidth) {
          nextOffset = 0
        }

        return nextOffset
      })

      cancelAnimationFrame(animationFrameRef.current)

      if (animate) {
        animationFrameRef.current = window.requestAnimationFrame(tick)
      }
    }

    cancelAnimationFrame(animationFrameRef.current)

    if (animate) {
      animationFrameRef.current = window.requestAnimationFrame(tick)
    }

    return () => {
      cancelAnimationFrame(animationFrameRef.current)
    }
  }, [animate])

  useEffect(() => {
    const updateCloneCount = () => {
      const count = (childrenRef.current && Math.ceil(
        Math.max(2, (window.innerWidth * 2) / childrenRef?.current?.clientWidth),
      )) || 2

      setCloneCount(count)
    }

    window.addEventListener('resize', updateCloneCount)

    setTimeout(() => {
      updateCloneCount()
    }, 1500)

    return () => {
      window.removeEventListener('resize', updateCloneCount)
    }
  }, [])

  useEffect(() => {
    setAnimate(inView)
  }, [inView])

  const handleMouseOver = () => {
    setAnimate(false)
  }

  const handleMouseOut = () => {
    setAnimate(true)
  }

  const clones = useMemo(() => [...new Array(cloneCount)].map((item, index) => (
    <Box
      // eslint-disable-next-line react/no-array-index-key
      key={`marquee-children-${index}`}
      position="absolute"
      top="0"
      left={`${(childrenRef?.current?.clientWidth || 0) * (index + 1)}px`}
    >
      {children}
    </Box>
  )), [children, cloneCount])

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Box
      ref={wrapperRef}
      position="relative"
      style={{ transform: `translateX(-${offset}px)` }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {React.Children.map(children, (child) => child && React.cloneElement(child, {
        ref: childrenRef,
      }))}
      {clones}
    </Box>
  )
}

Marquee.propTypes = {
  children: node,
}

Marquee.defaultProps = {
  children: null,
}

export default Marquee
