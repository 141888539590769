import React from 'react'
import styled from 'styled-components'
import {
  background, border, color, flexbox, layout, space, system, typography,
} from 'styled-system'
import { Box, FlexContainer, FlexGrid } from 'components/layout'
import { Image } from 'components/media'
import theme from 'styles/theme'

const StyledInput = styled.input`
  display: block;
  box-sizing: border-box;
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${system({
    textTransform: true,
  })}
  ${typography}
  appearance: none;
  max-width: 100%;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  outline: none;
  border: 1px solid black;
  border-radius: 0px;
  height: 48px;
  line-height: 48px;
`

const StyledButton = styled.button`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${system({
    textTransform: true,
  })}
  ${typography}
  appearance: none;
`

const CateringForm = ({ emailAddress, headline, media }) => {
  const handleSubmit = (evt) => {
    evt.preventDefault()

    const formData = new FormData(evt.currentTarget)
    const body = `
      Name: ${formData.get('name')}\n
      Email: ${formData.get('email')}\n
      # of People: ${formData.get('numberOfPeople')}\n
      Serving Date: ${formData.get('servingDate')}\n
    `

    window.location.href = `mailto:${emailAddress}?body=${encodeURIComponent(body)}`
  }

  return (
    <Box backgroundColor="white" pt={['40px', '80px']} pb={['80px', '62px']}>
      <FlexContainer position="relative">
        <FlexGrid cols={12}>
          {headline}
        </FlexGrid>
        <FlexGrid cols={[12, 6]}>
          <Image alt={media?.alt} src={media?.src} />
        </FlexGrid>
        <FlexGrid cols={[12, 1]} />
        <FlexGrid alignSelf="center" cols={[12, 4]} mt={['35px', '0px']}>
          <form onSubmit={handleSubmit}>
            <StyledInput
              id="name"
              name="name"
              placeholder="Full Name"
              border="1px solid black"
              fontFamily={theme.fonts.default}
              fontSize={['12px', '18px']}
              textTransform="uppercase"
              mb="20px"
            />
            <StyledInput
              id="email"
              name="email"
              placeholder="Email"
              border="1px solid black"
              fontFamily={theme.fonts.default}
              fontSize={['12px', '18px']}
              textTransform="uppercase"
              mb="20px"
            />
            <StyledInput
              id="numberOfPeople"
              type="number"
              min="0"
              max="1000"
              name="numberOfPeople"
              placeholder="Number of People"
              border="1px solid black"
              fontFamily={theme.fonts.default}
              fontSize={['12px', '18px']}
              textTransform="uppercase"
              mb="20px"
            />
            <StyledInput
              id="servingDate"
              name="servingDate"
              placeholder="Catering Date"
              border="1px solid black"
              fontFamily={theme.fonts.default}
              fontSize={['12px', '18px']}
              textTransform="uppercase"
              mb="20px"
            />
            <StyledButton
              type="submit"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="1px solid black"
              color="white"
              backgroundColor="black"
              fontSize={['14px', '20px']}
              fontWeight="500"
              textTransform="uppercase"
              px="20px"
              py="10px"
              margin="0"
              width={['100%', 'auto']}
            >
              Send
            </StyledButton>
          </form>
        </FlexGrid>
      </FlexContainer>
    </Box>
  )
}

CateringForm.defaultProps = {
  emailAddress: 'catering@eatbeatnic.com',
}

export default CateringForm
