import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Newsletter from 'components/newsletter/Newsletter'
import { Headline } from 'components/text'

const NewsletterWrapper = ({
  emailPlaceholder,
  headline,
  newsletterCopy,
  subscribeCtaCopy,
  subscribeCtaLink,
}) => (
  <Newsletter
    copy={<>{documentToReactComponents(newsletterCopy)}</>}
    cta={{ href: subscribeCtaLink, label: subscribeCtaCopy }}
    emailPlaceholder={emailPlaceholder}
    headline={<Headline color="black" document={headline} fontSize={['30px', '48px']} textTransform="uppercase" />}
  />
)

export default NewsletterWrapper
