import React from 'react'
import { arrayOf, node, shape } from 'prop-types'
import styled from 'styled-components'
import { isArray } from 'lodash'
import { Box } from 'components/layout'
import { Text } from 'components/text'
import theme from 'styles/theme'
import ContentGridItem from './ContentGridItem'

const ScrollWrapper = styled(Box)`
  *{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const ContentGrid = ({ content, headline }) => (
  <Box background="white" pt={['95px', '100px']} pb={['90px']} overflow="hidden" position="relative">
    <Box position="absolute" top="40px" left="-4px" zIndex="15">
      <Text as="h3">
        {headline}
      </Text>
    </Box>
    <ScrollWrapper overflowX="scroll" overflowY="hidden">
      <Box display="inline-flex" flexWrap="nowrap" px={[theme.grid.margin.sm, theme.grid.margin.md, theme.grid.margin.lg, theme.grid.margin.xl]}>
        {isArray(content) && content.map((item, index) => (
          <ContentGridItem
            key={`contentgrid-${item.id}-${index}`} // eslint-disable-line react/no-array-index-key
            media={item.media}
            title={item.title}
            url={item.url}
          />
        ))}
      </Box>
    </ScrollWrapper>
  </Box>
)

ContentGrid.propTypes = {
  content: arrayOf(shape()),
  headline: node,
}

ContentGrid.defaultProps = {
  content: null,
  headline: null,
}

export default ContentGrid
