import React from 'react'
import { arrayOf, shape } from 'prop-types'
import Hero from 'components/hero/Hero'
import Headline from 'components/text/Headline'

const HeroWrapper = ({ headline, icon, media }) => {
  const iconItem = {
    alt: icon?.descriptiopn,
    src: icon?.file?.url,
  }
  const mediaItems = media.map((item) => ({
    alt: item?.description,
    src: item?.file?.url,
  }))

  return (
    <Hero
      headline={<Headline document={headline} fontSize={['36px', '48px']} />}
      icon={iconItem}
      media={mediaItems}
    />
  )
}

HeroWrapper.propTypes = {
  headline: shape(),
  icon: shape(),
  media: arrayOf(shape()),
}

HeroWrapper.defaultProps = {
  headline: {},
  icon: null,
  media: [],
}

export default HeroWrapper
