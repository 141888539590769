import React from 'react'
import styled from 'styled-components'
import {
  background,
  color,
  minWidth,
  maxWidth,
  width,
  height,
  space,
  textAlign,
  layout,
  flexbox,
  position,
} from 'styled-system'
import { string } from 'prop-types'

const StyledImage = styled.img`
  display: block;
  width: 100%;
  ${background};
  ${color};
  ${position};
  ${minWidth};
  ${maxWidth};
  ${width};
  ${height};
  ${textAlign};
  ${space};
  ${layout};
  ${flexbox};
`

const Image = ({ alt, src, ...rest }) => <StyledImage src={src} alt={alt} {...rest} />

Image.propTypes = {
  alt: string,
  src: string,
}

Image.defaultProps = {
  alt: null,
  src: null,
}

export default Image
