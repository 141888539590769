import React from 'react'
import {
  node, oneOfType, shape, string,
} from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Box } from 'components/layout'
import Link from 'components/links/Link'
import { Image } from 'components/media'
import { Text } from 'components/text'
import theme from 'styles/theme'

const ContentGridItem = ({ media, title, url }) => {
  const margins = Object.keys(theme.grid.gutters).map((key) => `${theme.grid.gutters[key].replace('px', '') * 0.5}px`)

  return (
    <Box display="inline-table" width={['240px', '33.3%']} px={margins}>
      <Link href={url} textAlign="center" textDecoration="none">
        <Image src={media.src} alt={media.alt} />
        <Text
          as="h4"
          color="black"
          fontSize={['16px', '24px']}
          fontWeight={600}
          maxWidth="80%"
          mt={['15px', '30px']}
          mx="auto"
          textAlign="center"
        >
          {documentToReactComponents(title)}
        </Text>
      </Link>
    </Box>
  )
}

ContentGridItem.propTypes = {
  media: shape({ alt: string, src: string }),
  title: oneOfType([node, shape, string]),
  url: string,
}

ContentGridItem.defaultProps = {
  media: null,
  title: null,
  url: null,
}

export default ContentGridItem
