import React, { useState, useRef, useEffect } from 'react'
import {
  arrayOf, node, oneOfType, string, shape,
} from 'prop-types'
import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable'
import { useIsMobile } from 'components/layout'
import ProductCard from './ProductCard'

const Canvas = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 400px;
  transition: transform 0.3s ease-out;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    transition: transform 0.35s ease-out;
    height: 460px;
  }
  
  &.noCopy {
    height: 350px;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      height: 395px;
    }
  }
`

const Item = styled.div`
  display: block;
  position: absolute;
  width: 260px;
  height: 400px;
  transition: left 0.3s ease-out;
  box-shadow: 0px 4px 24px rgba(0,0,0,0.25);

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    transition: left 0.35s ease-out 0.075s;
    width: 285px;
    height: 460px;
  }

  &.noCopy {
    height: 350px;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      height: 395px;
    }
  }
`

const ProductCarousel = ({ products }) => {
  const ref = useRef()
  const isMobile = useIsMobile()
  const [active, setActive] = useState(0)
  const [width, setWidth] = useState(100)
  const [offset, setOffset] = useState(0)

  // swipeable
  const goToSlide = (newActive) => {
    const newOffset = isMobile
      ? 30 * (newActive / products.length)
      : 100 * (newActive / products.length)

    setActive(Math.max(0, Math.min(newActive, products.length - 1)))
    setOffset(newOffset)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => goToSlide(active + 1),
    onSwipedRight: () => goToSlide(active - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const getCardWidth = () => (isMobile ? 260 : 285)
  const getStep = () => Math.min(getCardWidth(), ((width - getCardWidth()) / (products.length - 1)))

  const calculatePosition = (index) => {
    const cw = getCardWidth()
    const step = getStep()

    if (index >= active) {
      return step * index
    }

    const overlap = cw - step
    const newX = -overlap + step * index
    return newX
  }

  const onMove = (e) => {
    const rect = ref.current.getBoundingClientRect()
    const x = e.clientX - rect.left
    const step = getStep()
    const newActive = Math.max(0, Math.min(products.length - 1, Math.floor(x / step)))
    goToSlide(newActive)
  }

  const onResize = () => {
    const rect = ref.current.getBoundingClientRect()
    setWidth(rect.width)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <Canvas
      {...handlers}
      onMouseMove={onMove}
      className={products.filter((p) => !!p.copy).length > 0 ? 'hasCopy' : 'noCopy'}
      style={{
        transform: `translateX(-${offset}px)`,
      }}
    >
      <div ref={ref}>
        {products.map((product, index) => (
          <Item
            className={product.copy ? 'hasCopy' : 'noCopy'}
            key={`productcarousel-${product.id}`}
            style={{
              top: '0px',
              left: calculatePosition(index),
              zIndex: products.length - index,
            }}
          >
            <ProductCard
              image={product.image}
              title={product.title}
              copy={product.copy}
              label={product.label}
              link={product.link}
            />
          </Item>
        ))}
      </div>
    </Canvas>
  )
}

ProductCarousel.propTypes = {
  products: arrayOf(shape({
    image: string,
    title: oneOfType([node, string]),
    copy: string,
  })).isRequired,
}

export default ProductCarousel
