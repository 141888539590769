import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Carousel } from 'components/carousel'
import { Headline } from 'components/text'

const CarouselWrapper = ({ carouselItems }) => (
  <Carousel
    items={carouselItems?.map((item) => ({
      id: item.id,
      copy: documentToReactComponents(item?.carouselItemDescription),
      cta: {
        href: item?.carouselItemCtaLink,
        label: item?.carouselItemCtaCopy,
      },
      headline: <Headline document={item?.headline} fontSize={['72px', '160px']} textAlign="center" />,
      media: {
        src: item?.carouselItemImage?.file?.url,
        alt: item?.file?.description,
      },
      title: (
        <Headline
          as="span"
          color="white"
          document={item?.subHeading}
          fontSize="30px"
          textAlign="center"
          textDecoration="underline"
          textTransform="none"
        />
      ),
    }))}
  />
)

CarouselWrapper.propTypes = {
  carouselItems: arrayOf(shape()),
}

CarouselWrapper.defaultProps = {
  carouselItems: [],
}

export default CarouselWrapper
