import React from 'react'
import { Box } from 'components/layout'
import { Text } from 'components/text'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import theme from 'styles/theme'
import { Burst } from 'components/icons'
import { shape, string } from 'prop-types'

const COLORS = {
  Blue: '#5C88DA',
  Red: '#D14124',
  Orange: '#ED8B00',
  Green: '#BABC16',
}

const StyledPill = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 23px;
  border-radius: 14px;
  padding-left: 10px; 
  padding-right: 10px; 
  
  @media (min-width: ${theme.breakpoints.lg}) {
    top: 20px;
    right: 20px;
    height: 33px;
    border-radius: 23px;
  }
`

const StyledBurst = styled(Box)`
  display: block;
  position: absolute;
  top: -30px;
  right: -15px;
  height: 88px;
  width: 88px;
  
  @media (min-width: ${theme.breakpoints.lg}) {
    top: -40px;
    right: -20px;
    height: 150px;
    width: 150px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Label = ({ data }) => {
  if (isEmpty(data)) {
    return null
  }

  if (data.style === 'Pill') {
    return (
      <StyledPill
        background={COLORS[data.color]}
      >
        <Text
          as="p"
          fontFamily={theme.fonts.RocGrotesk}
          fontSize={['12px', '12px', '16px']}
          fontWeight="600"
          color="white"
          style={{ textTransform: 'uppercase' }}
        >
          {data.text}
        </Text>
      </StyledPill>
    )
  }

  if (data.style === 'Burst') {
    return (
      <StyledBurst>
        <Burst fill={COLORS[data.color]} />
        <Text
          as="p"
          fontFamily={theme.fonts.RocGrotesk}
          fontSize={['16px', '16px', '24px']}
          fontWeight="600"
          color="black"
          style={{ textTransform: 'uppercase' }}
        >
          {data.text}
        </Text>
      </StyledBurst>
    )
  }

  return null
}

Label.propTypes = {
  data: shape({
    color: string,
    style: string,
    text: string,
  }),
}

Label.defaultProps = {
  data: null,
}

export default Label
