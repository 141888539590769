import React from 'react'
import { useRouter } from 'next/router'
import {
  node, oneOfType, shape, string,
} from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { Box } from 'components/layout'
import Text from 'components/text/Text'
import Label from 'components/menu/Label'
import TruncateMarkup from 'react-truncate-markup'

const Container = styled.div`
  position: relative;
  width: 260px;
  height: 400px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #C9DE00;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 285px;
    height: 460px;
  }

  &.noCopy {
    height: 350px;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      height: 395px;
    }
  }

  &.hasLink {
    cursor: pointer;
  }
`

const ImageContainer = styled.div`
  display: block;
  height: 260px;
  width: 260px;
  background-size: cover;
  background-position: center center;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 285px;
    height: 285px;
  }
`

const ProductCard = ({
  image, title, copy, label, link,
}) => {
  const router = useRouter()
  const className = classNames(
    { noCopy: !copy },
    { hasLink: !!link },
  )

  const onClick = () => {
    if (link) {
      router.push(link)
    }
  }

  return (
    <Container className={className} onClick={onClick}>
      <ImageContainer
        style={{
          backgroundImage: `url(/${image})`,
        }}
      />
      <Box p="20px">
        <Text
          color="black"
          fontWeight="900"
          fontSize={['16px', '16px', copy ? '20px' : '24px']}
        >
          {title}
        </Text>
        {copy && (
          <TruncateMarkup
            lines={3}
            tokenize="words"
          >
            <Text
              as="div"
              pt="10px"
              color="tundora"
              fontWeight="500"
              lineHeight="15.84px"
              fontSize={['11px', '11px', '14px']}
            >
              {copy}
            </Text>
          </TruncateMarkup>
        )}
        <Label data={label} />
      </Box>
    </Container>
  )
}

ProductCard.propTypes = {
  image: string.isRequired,
  title: oneOfType([node, string]).isRequired,
  copy: string,
  label: shape({}),
  link: string,
}

ProductCard.defaultProps = {
  copy: null,
  label: null,
  link: null,
}

export default ProductCard
