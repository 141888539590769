import React from 'react'
import { arrayOf, node, shape } from 'prop-types'
import { Box, Grid, Container } from 'components/layout'
import { Image } from 'components/media'
import { Text } from 'components/text'

const Hero = ({ headline, icon, media }) => (
  <Box
    background="black"
    backgroundImage={['', "url('/images/hero-waves.svg')"]}
    backgroundPosition="bottom right"
    backgroundRepeat="no-repeat"
    backgroundSize="565px 565px"
    pt={['20px', '40px']}
    pb={['30px', '60px']}
  >
    <Container>
      <Grid col="1/13">
        <Text as="h1" maxWidth={['223px', '100%']}>
          {headline}
        </Text>
        <Box position="relative">
          {media.map((item) => (
            <Image
              key={`hero-image-${item}`}
              src={`${item.src}?w=2400`}
              alt={item.alt}
              mt="20px"
            />
          ))}
          {icon && (
            <Box
              position="absolute"
              top={['-54px', '-105px']}
              right={['4px', '86px']}
              width={['108px', '211px']}
            >
              <Image src={icon.src} alt={icon.alt} />
            </Box>
          )}
        </Box>
      </Grid>
    </Container>
  </Box>
)

Hero.propTypes = {
  headline: node,
  icon: shape(),
  media: arrayOf(shape()),
}

Hero.defaultProps = {
  headline: 'Live life till\nyou’re full',
  icon: null,
  media: [{ alt: '', src: '/hero.jpg' }],
}

export default Hero
