import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MediaCopy } from 'components/sections'
import { Headline } from 'components/text'

const MediaCopyWrapper = ({ copy, headline, media }) => (
  <MediaCopy
    copy={documentToReactComponents(copy)}
    headline={<Headline document={headline} fontSize={['60px', '100px']} />}
    media={{
      alt: media?.file?.description,
      src: media?.file?.url,
    }}
  />
)

export default MediaCopyWrapper
