import React from 'react'
import { arrayOf, shape } from 'prop-types'
import ContentGrid from 'components/sections/ContentGrid'
import { Headline } from 'components/text'

const ContentGridWrapper = ({ content, headline }) => (
  <ContentGrid
    content={content?.map((item) => ({
      media: {
        alt: item?.media?.description,
        src: item?.media?.file?.url,
      },
      title: item?.title,
      url: item?.url,
    }))}
    headline={<Headline color="black" document={headline} fontSize={['60px', '100px']} />}
  />
)

ContentGridWrapper.propTypes = {
  content: arrayOf(shape()),
  headline: shape(),
}

ContentGridWrapper.defaultProps = {
  content: null,
  headline: null,
}

export default ContentGridWrapper
