import React from 'react'
import { shape, string } from 'prop-types'
import CateringForm from 'components/forms/CateringForm'
import { Headline } from 'components/text'

const CateringFormWrapper = ({ emailAddress, headline, media }) => (
  <CateringForm
    emailAddress={emailAddress || undefined}
    headline={<Headline color="black" document={headline} fontSize={['40px', '90px']} />}
    media={{
      alt: media?.file?.description,
      src: media?.file?.url,
    }}
  />
)

CateringFormWrapper.propTypes = {
  emailAddress: string,
  headline: shape(),
  media: shape(),
}

CateringFormWrapper.defaultProps = {
  emailAddress: null,
  headline: null,
  media: null,
}

export default CateringFormWrapper
