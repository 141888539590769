import React from 'react'
import { shape } from 'prop-types'
import FullBleedImage from 'components/media/FullBleedImage'

const FullBleedImageWrapper = ({ fullBleedAsset }) => (
  <FullBleedImage image={fullBleedAsset?.file?.url} />
)

FullBleedImageWrapper.propTypes = {
  fullBleedAsset: shape(),
}

FullBleedImageWrapper.defaultProps = {
  fullBleedAsset: null,
}

export default FullBleedImageWrapper
