import React from 'react'

const parseLineBreaks = (input) => {
  const addBreaks = (value) => {
    const parts = value.split('\n')
    return parts.map((part) => (
      <>
        {part}
        <br />
      </>
    ))
  }

  if (Array.isArray(input)) {
    return input.map((item) => {
      if (typeof item === 'string') {
        return addBreaks(item)
      }

      return item
    })
  }

  if (typeof input === 'string') {
    return addBreaks(input)
  }

  return input
}

export default parseLineBreaks
