import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BrandHero } from 'components/hero'

const BrandHeroWrapper = ({ copy, media }) => (
  <BrandHero
    copy={documentToReactComponents(copy)}
    media={{
      src: media?.file?.url,
      alt: media?.file?.description,
    }}
  />
)

export default BrandHeroWrapper
