import React from 'react'
import { BrandLogo } from 'components/media'
import {
  BrandHeroWrapper,
  CarouselWrapper,
  CateringFormWrapper,
  ContentGridWrapper,
  FullBleedImageWrapper,
  HeroWrapper,
  InstagramFeedWrapper,
  MediaCopyWrapper,
  NewsletterWrapper,
  ProductTrayWrapper,
  RichTextWrapper,
  SimpleHeroWrapper,
} from './componentWrappers'

const componentMap = {
  carouselModule: (item, key) => <CarouselWrapper key={key} {...item} />,
  fullBleedModule: (item, key) => <FullBleedImageWrapper key={key} {...item} />,
  hero: (item, key) => <HeroWrapper key={key} {...item} />,
  instaFeed: (item, key) => <InstagramFeedWrapper key={key} {...item} />,
  newsletter: (item, key) => <NewsletterWrapper key={key} {...item} />,
  productTrayModule: (item, key) => <ProductTrayWrapper key={key} {...item} />,
  sectionBrandHero: (item, key) => <BrandHeroWrapper key={key} {...item} />,
  sectionBrandLogoAnimation: (item, key) => <BrandLogo key={key} />,
  sectionCateringForm: (item, key) => <CateringFormWrapper key={key} {...item} />,
  sectionContentGrid: (item, key) => <ContentGridWrapper key={key} {...item} />,
  sectionMediaCopy: (item, key) => <MediaCopyWrapper key={key} {...item} />,
  sectionRichText: (item, key) => <RichTextWrapper key={key} {...item} />,
  sectionSimpleHero: (item, key) => <SimpleHeroWrapper key={key} {...item} />,
}

componentMap.carouselModule.displayName = 'CarouselWrapper'
componentMap.fullBleedModule.displayName = 'FullBleedImageWrapper'
componentMap.hero.displayName = 'HeroWrapper'
componentMap.instaFeed.displayName = 'InstagramFeedWrapper'
componentMap.newsletter.displayName = 'NewsletterWrapper'
componentMap.productTrayModule.displayName = 'ProductTrayWrapper'
componentMap.sectionBrandHero.displayName = 'BrandHeroWrapper'
componentMap.sectionBrandLogoAnimation.displayName = 'BrandLogo'
componentMap.sectionCateringForm.displayName = 'CateringFormWrapper'
componentMap.sectionContentGrid.displayName = 'ContentGridWrapper'
componentMap.sectionMediaCopy.displayName = 'MediaCopyWrapper'
componentMap.sectionRichText.displayName = 'RichTextWrapper'
componentMap.sectionSimpleHero.displayName = 'SimpleHeroWrapper'

export default componentMap
