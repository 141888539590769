import componentMap from './componentMap'

const ComponentRenderer = ({ data }) => {
  const renderComponent = (item) => {
    const { id, __typename: type } = item

    if (!(type in componentMap)) {
      return null
    }

    return componentMap[type](item, id)
  }

  if (Array.isArray(data)) {
    return data.map((item) => renderComponent(item))
  }

  return renderComponent(data)
}

export default ComponentRenderer
